import React from "react";
import { graphql } from "gatsby";

import Helmet from "react-helmet";

import siteConfig from "../../data/SiteConfig";

import Page from "../components/Layout/Page";
import { FixedWidthContainer } from "../components/Layout/ContentContainers";

import PostList from "../components/PostList";

class StaffPicksPage extends React.Component {
  constructor(props) {
    super(props);

    const { data } = this.props;

    const postEdges = data.allWordpressPost.edges.filter(post => {
      let match = false;
      post.node.categories.forEach(category => {
        if (!match && category.name === "Blog") {
          match = true;
        }
      });
      return match;
    });

    this.state = {
      postEdges
    };
  }

  getMetaUrl() {
    const { title } = this.props;
    let baseUrl = siteConfig.siteUrl;
    if (title) {
      baseUrl += `/blog`;
    }
    return baseUrl;
  }

  getMetaTitle() {
    let pageTitle = siteConfig.siteTitle;

    pageTitle = `Blog | ${pageTitle}`;
    return pageTitle;
  }

  render() {
    const { postEdges } = this.state;
    return (
      <Page title="The FEST Blog">
        <Helmet title={this.getMetaTitle()}>
          <meta property="og:title" content={this.getMetaTitle()} />
          <meta property="og:url" content={this.getMetaUrl()} />
        </Helmet>

        <FixedWidthContainer>
          <PostList postEdges={postEdges} />
        </FixedWidthContainer>
      </Page>
    );
  }
}

export default StaffPicksPage;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query BlogPageQuery {
    allWordpressPost(sort: { fields: [date], order: DESC }) {
      edges {
        node {
          date
          slug
          title
          modified
          excerpt
          id
          acf {
            external_published_date
          }
          categories {
            name
          }
          content
        }
      }
    }
  }
`;
